import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import IndexedDBWrapper from './indexed_db';

export default class extends Controller {
  static targets = ["form", "uid", "contactDetails", "savePatientButton", "socialSituation", "addressChangedButton", "socialSituationChangedButton", "nextTab", "currentConsultation"];

  connect() {
    this.isOfflineEditForm = (window.location.pathname.startsWith("/patients/0/consultations/0") && !window.location.search.includes("new_client=true")) || window.location.pathname.startsWith("/consultations/offline_pages");
    this.db = new IndexedDBWrapper();
    if (this.isOfflineEditForm) {
      this.loadOfflineData();
    } else {
      if (!this.uidTarget.value) this.uidTarget.value = this.generateUid();
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('is_offline')) {
        urlParams.set('clientId', this.uidTarget.value);
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState({}, '', newUrl);
        this.isNewClient = true;
      }
    }
  }

  generateUid() {
    return 'P-' + Math.random().toString(36).substring(2, 11);
  }

  storeOnlineData(isNextTab) {
    if(!navigator.onLine) return;

    const url = this.formTarget.action;
    if (!url) return;

    let formData = new FormData(this.formTarget);
    const currentConsultationId = this.currentConsultationTarget.value;
    formData.append('consultation_id', currentConsultationId);
    formData.append('next_tab', isNextTab);
    formData.append('patient[is_draft]', 'false');
    formData.append('patient[phone]', this.getPhoneNumberController().getPhoneNumber());
    Rails.ajax({
      type: 'PUT',
      url: url,
      data: formData,
      success: (_data) => {
      }
    })
    return true;
  }

  storeOfflineData() {
    if (navigator.onLine) return;

    let formData = new FormData(this.formTarget);
    let formValues = {};

    for (let [key, value] of formData.entries()) {
      if (!key.includes("patient") || formValues[key]) continue;

      formValues[key] = value;
    }

    this.formTarget.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      if (!checkbox.name.includes("patient")) return;

      formValues[checkbox.name] = checkbox.checked ? 'checked' : 'unchecked';
    });

    formValues.id = this.uidTarget.value;
    return this.db.addClient(formValues);
  }

  handleSubmit(event, cb) {
    toastr.success("Client data saved offline");
    if (navigator.onLine && !this.isOfflineEditForm) return;

    event.preventDefault();
    this.storeOfflineData().then(() => {
      toastr.success("Client data saved offline");
      if (window.location.pathname.startsWith("/patients/0/consultations/0")) {
        // window.location.href = "/patients/offline_view";
        cb();
      } else {
        window.location.reload();
      }
    });
  }

  loadOfflineData() {
    const params = new URLSearchParams(window.location.search);
    const clientId = params.get('clientId');
    if (!clientId) return;
    this.db.getClient(clientId).then((client) => {
      if (!client) return;
      Object.keys(client).forEach(key => {
        if (key.includes("patient")) {
          const element = document.querySelector(`[name="${key}"]`);
          if (element) {
            element.value = client[key];
            if (key.includes("country")) element.dispatchEvent(new Event('change'));
            if (key.includes("state")) {
              setTimeout(() => {
                element.value = client[key];
              }, 1000);
            }
          }
          if (element && element.type === "checkbox") element.checked = (client[key] === "checked");
        }
      });
      this.toggleSinceLastVisitDisplays();
    })
  }

  saveClient(event) {
    if (!this.hasFormTarget || !this.hasSavePatientButtonTarget) return;
    navigator.onLine ? this.storeOnlineData() : this.handleSubmit(event);
  }

  setNextTabValue(value) {
    this.nextTabTarget.value = value;
  }

  toggleContactDetails(event) {
    if (this.isOfflineEditForm) {
      if (event.target.checked) {
        this.contactDetailsTarget.style.display = "block";
      } else {
        this.contactDetailsTarget.style.display = "none";
      }
      return;
    }
    const url = event.target.dataset.url;
    const formData = new FormData();
    formData.append('consultation[is_address_changed]', event.target.checked);
    Rails.ajax({
      type: 'PUT',
      url: url,
      data: formData,
      dataType: 'json',
      success: (_data) => {
        if (event.target.checked) {
          this.contactDetailsTarget.style.display = "block";
        } else {
          this.contactDetailsTarget.style.display = "none";
        }
      }
    })
  }

  toggleSocialSituation(event) {
    if (this.isOfflineEditForm) {
      if (event.target.checked) {
        this.socialSituationTarget.style.display = "block";
      } else {
        this.socialSituationTarget.style.display = "none";
      }
      return;
    }
    const url = event.target.dataset.url;
    const formData = new FormData();
    formData.append('consultation[is_social_situation_changed]', event.target.checked);
    Rails.ajax({
      type: 'PUT',
      url: url,
      data: formData,
      dataType: 'json',
      success: (_data) => {
        if (event.target.checked) {
          this.socialSituationTarget.style.display = "block";
        } else {
          this.socialSituationTarget.style.display = "none";
        }
      }
    })
  }

  toggleSinceLastVisitDisplays() {
    if (this.addressChangedButtonTarget.checked) {
      this.contactDetailsTarget.style.display = "block";
    } else {
      this.contactDetailsTarget.style.display = "none";
    }

    if (this.socialSituationChangedButtonTarget.checked) {
      this.socialSituationTarget.style.display = "block";
    } else {
      this.socialSituationTarget.style.display = "none";
    }
  }

  getPhoneNumberController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('[data-controller="phone-number"]'), "phone-number");
	}
}
